import React from 'react';

import { About, Footer, Header, Skills, Testimonial, Work } from './container';
import { BrowserRouter as Router} from 'react-router-dom';
import { Navbar } from './components';
import './App.scss';

const App = () => (
  <div className="app">
    <Router>
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      <Testimonial />
      <Footer />
    </Router>
  </div>
);

export default App;
